/*
*
* File JS Principale
*
*/


// IMPORT SCSS PER LA COMPILAZIONE
//--------------------------------------------------------------------//

import 'scss/app.scss';

//--------------------------------------------------------------------//

// Carichiamo i font in maniera asincrona
//--------------------------------------------------------------------//

const loadFontByLang = () => {
    
    let Webfont = require('webfontloader');

    let langSlug = document.documentElement.lang;

    let font = 'Raleway:300,400,500,600,700,800&display=swap';

    switch (langSlug) {
        case 'gr':
            font = 'Lato:300,400,500,600,700,800&display=swap';
            break;
    
        default:
            font = 'Raleway:300,400,500,600,700,800&display=swap';
            break;
    }

    Webfont.load({
        google: {
            families: [font]
        }
    });

}

loadFontByLang();

//--------------------------------------------------------------------//

// Libreria Lazysizes
//--------------------------------------------------------------------//

import 'lazysizes';

//--------------------------------------------------------------------//




// Import Utilies
//--------------------------------------------------------------------//

import smoothScroll from './utilities/smooth-scroll';


//--------------------------------------------------------------------//


// Init Utilies
//--------------------------------------------------------------------//

smoothScroll();

//--------------------------------------------------------------------//


// Import Componenti
//--------------------------------------------------------------------//

import header from 'components/header/header';
import accordion from 'imComponents/accordion/accordion';
import footer from 'components/footer/footer';
import comingSoon from '../components/coming-soon/coming-soon';

//--------------------------------------------------------------------//


// Init Componenti
//--------------------------------------------------------------------//
comingSoon();


