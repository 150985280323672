/*
*
* File JS componente HEADER
*
*/


// IMPORT SCSS PER LA COMPILAZIONE
//--------------------------------------------------------------------//

import './header.scss';

//--------------------------------------------------------------------//


// EXPORT COMPONENTE
//--------------------------------------------------------------------//

const html = document.querySelector('html');
const body = document.querySelector('body');
const header = document.querySelector('header');
const headerWrap = document.querySelector('header .header-wrap');
const menuContainer = document.querySelector('header .header-wrap .menu-container');
const hamburgerBtn = document.querySelector('.header-wrap .header__burger');

const linksWithSubMenu = document.querySelectorAll('header .header-wrap .top-menu > ul > li.dropdown');
const anchorLinksSubMenu = document.querySelectorAll('header .header-wrap .top-menu > ul > li.dropdown > a');


/**
*	Funzione di gestione apertura/chiusura menu mobile
*
*	@return {void}
*/
const manageMenuMobile = () =>
{
    hamburgerBtn.addEventListener('click', () =>
    {
        if (hamburgerBtn.classList.contains('open'))
        {
            header.classList.remove('open-menu');
            headerWrap.classList.remove('open-menu');
            hamburgerBtn.classList.remove('open');
            body.classList.remove('open-menu-mobile');
            html.classList.remove('ovfw-hid-nav-mob');

        }
        else
        {
            // heightMenuMobile();
            header.classList.add('open-menu');
            headerWrap.classList.add('open-menu');
            hamburgerBtn.classList.add('open');
            body.classList.add('open-menu-mobile');
            html.classList.add('ovfw-hid-nav-mob');
        }
    });

    // Chiusura tasto ESC
    document.addEventListener('keyup', (evt) =>
    {
        if(evt.code === 'Escape')
        {
            if (!hamburgerBtn.classList.contains('open')) return;
            
            header.classList.remove('open-menu');
            headerWrap.classList.remove('open-menu');
            hamburgerBtn.classList.remove('open');
            body.classList.remove('open-menu-mobile');
            html.classList.remove('ovfw-hid-nav-mob');
        }
    });
}


/**
*	Funzione di apertura del submenu
*
*	@return {void}
*/
const openSubMenu = (el) =>
{
    el.classList.add('open');
    headerWrap.classList.add('open-submenu');

    if (window.innerWidth >= 1024)
    {
        el.querySelector(':scope > .dropdown-menu').style.height = '';
    }
    else
    {
        let heightSubmenu = 0;
        let elementsInSub = el.querySelectorAll(':scope > .dropdown-menu > li');

        elementsInSub.forEach(element => 
        {
            heightSubmenu = heightSubmenu + element.offsetHeight;
        });

        el.querySelector(':scope > .dropdown-menu').style.height = heightSubmenu + 'px';
    }

}


/**
*	Funzione di chiusura del submenu
*
*	@return {void}
*/
const closeSubMenu = (el) =>
{
    el.classList.remove('open');
    headerWrap.classList.remove('open-submenu');

    if (window.innerWidth >= 1024) return;

    el.querySelector(':scope > .dropdown-menu').style.height = '';
}


/**
*	Funzione di chiusura di tutti i submenu
*
*	@return {void}
*/
const closeAllSubMenu = () => 
{
    linksWithSubMenu.forEach(el => {
        closeSubMenu(el);
    });
}


/**
*	Funzione per la gestione dell'apertura/chiusura dei submenu
*
*	@return {void}
*/
const manageSubMenu = () => 
{
    const iconDropdown = '<div class="submenu-indicator"><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" shape-rendering="geometricPrecision" class="icons-down"><use xlink:href="assets/build/spritemap.svg#ico_down"></use></svg></div>';

    for (let i = 0; i < linksWithSubMenu.length; ++i) 
    {
        // Add icon dropdown
        linksWithSubMenu[i].innerHTML = linksWithSubMenu[i].innerHTML + iconDropdown;

        // START - EventListener Navbar expanded
        linksWithSubMenu[i].addEventListener('click', (evt) => 
        {
            if (window.innerWidth < 1080) return;

            if (linksWithSubMenu[i].classList.contains('open'))
            {
                closeSubMenu(linksWithSubMenu[i]);
            }
            else
            {
                openSubMenu(linksWithSubMenu[i]);
            }
        });

        document.addEventListener('click', (evt) => {

            if (window.innerWidth < 1080) return;
            
            let isClickInside = linksWithSubMenu[i].contains(evt.target);

            if (!isClickInside) 
            {
                closeSubMenu(linksWithSubMenu[i]);
            }
        });
        // END - EventListener Navbar expanded

        // START - EventListener Navbar compressed
        linksWithSubMenu[i].addEventListener('click', (evt) => 
        {
            if (window.innerWidth >= 1080) return;

            if (!linksWithSubMenu[i].classList.contains('open'))
            {
                openSubMenu(linksWithSubMenu[i]);
            } 
            else 
            {
                closeSubMenu(linksWithSubMenu[i]);
            }
        });
        // END - EventListener Navbar compressed
    }
}


/**
*	Funzione per calcolo altezza menu mobile 
*
*	@return {void}
*/
const heightMenuMobile = () =>
{
    let wHeight = window.innerHeight;
    let wWidth = window.innerWidth;
    let offsetTopElements = 0;

    var topbar = document.querySelector('.block-top-bar');

    var topbarHeight = topbar ? topbar.offsetHeight : 0;

    if (wWidth < 1024)
    {
        if (body.classList.contains('scroll-detect'))
        {
            offsetTopElements = document.querySelector('header .header-container').offsetHeight;
        }
        else
        {
            // offsetTopElements = topbarHeight + document.querySelector('header .header-container').offsetHeight;
            offsetTopElements = topbarHeight + 39;
        }

        menuContainer.style.height = (wHeight - offsetTopElements) + 'px';
    }
    else
    {
        menuContainer.style.height = '';
    }
}


/**
*	Funzione per la gestione dell'animazione allo scroll della navbar
*
*	@return {void}
*/
// const scrollDetectNav = () => {
//     let scrollUp = 'scroll-up';
//     let scrollDown = 'scroll-down';
//     let scrollDetect = 'scroll-detect';
//     let lastScroll = 0;

//     let navbarHeight = document.querySelector('header .header-wrap').offsetHeight;

//     window.addEventListener('resize', () => {
//         navbarHeight = document.querySelector('header .header-wrap').offsetHeight;
//     });

//     window.addEventListener('scroll', () => {
//         const currentScroll = window.pageYOffset;

//         if (body.classList.contains('open-submenu') || body.classList.contains('open-menu-mobile') || body.classList.contains('search-modal-open')) return;
        
//         if (currentScroll == 0) 
//         {
//             body.classList.remove(scrollUp, scrollDetect);
//             return;
//         }
        
//         if (currentScroll > lastScroll && !body.classList.contains(scrollDown) && currentScroll >= navbarHeight) 
//         {
//             // Scroll Down
//             body.classList.add(scrollDetect);
//             closeLangSwitch();
            
//             if (currentScroll >= (navbarHeight + 30))
//             {
//                 body.classList.remove(scrollUp);
//                 body.classList.add(scrollDown);
//             }
//         } 
//         else if (currentScroll < lastScroll && body.classList.contains(scrollDown)) 
//         {
//             // Scroll Up
//             body.classList.remove(scrollDown);
//             body.classList.add(scrollUp);
//         }
//         lastScroll = currentScroll;
//     });
// }

const scrollDetectNav = () => {
    let scrollUp = 'scroll-up';
    let scrollDown = 'scroll-down';
    let scrollDetect = 'scroll-detect';
    let lastScroll = 0;

    let topbar = document.querySelector('.block-top-bar');

    let topbarHeight = topbar ? topbar.offsetHeight : 0;
    let navbarHeight = document.querySelector('header').offsetHeight;

    window.addEventListener('resize', function()
    {
        topbarHeight = document.querySelector('.block-top-bar') ? document.querySelector('.block-top-bar').offsetHeight : 150;
        navbarHeight = document.querySelector('header').offsetHeight;
    });

    const manageScroll = () => {
        const currentScroll = window.pageYOffset;
        
        if (currentScroll <= topbarHeight) 
        {
            body.classList.remove(scrollUp, scrollDetect);
            return;
        }
        
        if (currentScroll > lastScroll && !body.classList.contains(scrollDown) && currentScroll >= navbarHeight) 
        {
            // Scroll Down
            body.classList.add(scrollDetect);
            
            if (currentScroll >= navbarHeight)
            {
                body.classList.remove(scrollUp);
                body.classList.add(scrollDown);
            }
        } 
        else if (currentScroll < lastScroll && body.classList.contains(scrollDown)) 
        {
            // Scroll Up
            body.classList.remove(scrollDown);
            body.classList.add(scrollUp);
        }
        lastScroll = currentScroll;
    }
    manageScroll();

    window.addEventListener('scroll', manageScroll);
}


/**
*	Funzione per la chiusura dell'accordion dello switch lingua
*
*	@return {void}
*/
const closeLangSwitch = () => {
    let toggle = document.querySelector('.im-accordion__toggle[data-accordion="switch-lang"]');
    let content = document.querySelector('.im-accordion__content[data-accordion="switch-lang"]');
    
    toggle.classList.remove('open');
    content.classList.remove('open');

    content.style.height = '0px';
}


/**
*	Funzione di init per tutte le funzioni del componente Header
*
*	@return {void}
*/
const initHeader = () =>
{
 //   manageMenuMobile();
    manageSubMenu();
    // heightMenuMobile();
    scrollDetectNav();

    window.addEventListener('resize', () => {
        closeAllSubMenu();
        // heightMenuMobile();
    });

    document.addEventListener('keydown', (evt) => {
        if(evt.key === "Escape"){
            closeAllSubMenu();
        }
    });
}

export default initHeader;

//--------------------------------------------------------------------//